export function isString(v: unknown): v is string {
  return typeof v === 'string';
}

export function isNumber(v: unknown): v is number {
  return typeof v === 'number' && !Number.isNaN(v);
}

export function isBoolean(v: unknown): v is boolean {
  return typeof v === 'boolean';
}

export function isObject(v: unknown): v is object {
  return typeof v === 'object' && !Array.isArray(v) && v !== null;
}

export function isFunction(v: unknown): v is Function {
  return typeof v === 'function';
}

export function isArray<T = unknown>(v: unknown): v is Array<T> {
  return Array.isArray(v);
}

export function isUndefined(v: unknown): v is undefined {
  return typeof v === 'undefined';
}

export function isNull(v: unknown): v is null {
  return v === null;
}

export function isNullish(v: unknown): v is null | undefined {
  return v == null;
}

export function isInstance<T extends Function>(v: unknown, constructor: T): v is T {
  return v instanceof constructor || v?.constructor === constructor;
}

export function isEmpty(v: unknown): v is null | undefined | '' | false | 0 | object | [] {
  if (v == null) return true;
  const isObjectValue = isObject(v);
  const isBooleanValue = typeof v === 'boolean' && v;
  const isImplicitValue = isNumber(v) || isFunction(v) || isBooleanValue;
  const isEmptyObject = isObjectValue && Object.keys(v).length === 0;
  const isEmptyArray = isArray(v) && v.length === 0;
  return isEmptyObject || isEmptyArray || (!isImplicitValue && !isObjectValue && !v);
}

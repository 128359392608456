
import { Component, Vue, Prop, Ref } from 'nuxt-property-decorator';

@Component({})
export default class Hint extends Vue {
  @Prop({ type: String }) content!: string;
  @Prop({ type: String }) position!: string;
  @Prop({ type: String, default: 'body' }) container!: string;
  @Prop(Boolean) readonly disabled!: boolean;

  @Ref() hintButton?: HTMLElement;

  isActive = false;

  public onFocusOut() {
    this.isActive = false;
    this.hintButton?.focus();
  }

  public handleKeydown(event: KeyboardEvent) {
    const isSpaceOrEnter = event.key === 'Enter' || event.key === ' ';

    if (isSpaceOrEnter) {
      this.isActive = !this.isActive;
      event.preventDefault();
    }
  }
}
